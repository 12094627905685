@import '~styles/variables';

.WebhooksList {

    .list-header {

        .Button {
            margin-bottom: 0;
        }
    }
}