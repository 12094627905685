@import "~styles/variables";
@import "~styles/helpers";

.UsageReport {

  .list-header {
    .Button {
      height: 42px;
    }
  }

  .List {
    .description-cell {
      display: flex;
      align-items: center;

      svg {
        flex-shrink: 0;
      }

      span {
        margin-left: 4px;
        word-break: break-word;
      }
    }
    .SubComponent {
      .subheading {
        margin: 10px 0 0 20px;
        font-weight: 500;
        font-size: 14px;
      }

      .empty-data-warning {
        margin: 6px 0 15px 20px;
        font-size: 12px;
      }

      .data-container {
        padding: 0 20px 20px;

        .subheading {
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}