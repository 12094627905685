@import "~styles/variables";

.HelpNavigation {
  margin-right: 50px;
  position: relative;

  .help-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    svg {
      margin-right: 5px;
    }

    span {
      color: $white;
    }
  }

  .Dropdown {
    padding: 0;

    .li-content {
      cursor: pointer;
      outline: none;
      padding: 10px 15px !important;
  
      &:hover {
        background-color: $option-hover-background;
      }
    }
  }
}
