@import "~styles/variables";
@import "~styles/helpers";

.ProductList {

  &-filters {
    margin-bottom: 10px;

    .AdvancedSearch-btn {
      @include appearance(none);
      font-size: 14px;
      color: $blue;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      outline: none;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .AdvancedSearch-wrapper {
      overflow: visible;
      transition: all .4s cubic-bezier(0.25, 1, 0.5, 1);
      @include animate(visibleOverflow, 400ms, ease-in, 0);

      &.hide {
        overflow: hidden;
        padding-bottom: 0px;
        @include animate(hiddenOverflow, 10ms, ease-in, 0);
      }
    }

    .AdvancedSearch {
      display: block;
      background-color: $white;
      border: 1px solid $form-element-border;
      margin-top: 3px;
      max-height: 500px;
      opacity: 1;
      // transform: translateY(0);
      transition: all .4s cubic-bezier(0.25, 1, 0.5, 1);
    
      &.hide {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-230px);
      }
    
      &-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    
        .filter-section {
          width: 48%;
          padding: 0 0 0 20px;
        }

        .more-filters {
          width: 100%;
          margin-top: 20px;

          .heading {
            display: flex;
            align-items: center;
            @include appearance(none);
            padding: 0 0 0 20px;
            font-weight: 500;
            font-size: 13px;
            color: $blue;
            cursor: pointer;
            background-color: transparent;
            border: none;
            outline: none;

             svg {
              fill: $blue;
              height: 15px;
              width: 15px;
              margin-left: 5px;
              transform: rotate(0);
              transition: transform .3s cubic-bezier(0.25, 1, 0.5, 1);

              &.open {
                transform: rotate(-180deg);
              }
            }
          }

          &-wrapper {
            overflow: visible;
            @include animate(visibleOverflow, 400ms, ease-in, 0);
      
            &.hide {
              overflow: hidden;
              @include animate(hiddenOverflow, 10ms, ease-in, 0);
            }

            .more-filters-fields {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              max-height: 500px;
              opacity: 1;
              // transform: translateY(0);
              transition: all .3s cubic-bezier(0.25, 1, 0.5, 1);
            
              &.hide {
                max-height: 0;
                opacity: 0;
                transform: translateY(-80px);
              }
            }
          }
        }
      }
    
      &-actions {
        margin-top: 20px;
        background-color: #f0f0f0;
        width: 100%;
        padding: 5px 0 5px 20px;

        .Button {
          margin-right: 40px;
          margin-top: 10px;
        }
      }
    }

    .Filters-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
    }
  }
}

@include keyframe(visibleOverflow) {
  from { overflow: hidden; }
  to { overflow: visible; }
}

@include keyframe(hiddenOverflow) {
  from { overflow: visible; }
  to { overflow: hidden; }
}