@import "~styles/variables";
@import "~styles/helpers";

.LicenseManagerProductForm {

  .input-cont {
    .NumberInput {
      input {
        max-width: 400px;
      }
    }
  }

  &-selected-policies {
    margin: 10px;
    padding-inline-start: 0;

    li {
      font-size: 13px;
      color: $text-color-secondary;
      margin-bottom: 4px;
    }
  }
}