@import "~styles/variables";

.NumberInput {
  position: relative;

  input {
    height: 34px;
    width: 100%;
    max-width: 300px;
    border: 1px solid $form-element-border;
    outline: none;
    padding: 0 10px;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;

    &::-webkit-inner-spin-button {
      opacity: 1;
    }

    &:disabled {
      background-color: hsl(0,0%,95%);

      &:hover {
        border: 1px solid $form-element-border;
      }
    }

    &:focus,
    &:hover {
      border: 1px solid $form-element-border-focus;
    }

    &.has-error {
      border-color: $red;
    }
  }

  .Spinner {
    position: absolute;
    right: 31px;
    top: 4px;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}