@import "~styles/variables";
@import "~styles/helpers";

.Integrations {
  .row {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-bottom: 30px;

    h3 {
      margin-top: 0px;
      margin-bottom: 1px;
      font-weight: 600;
      font-size: 18px;
      color: $text-color-primary;
    }

    .Button {
      max-width: 140px;
    }
  }

  .docs-link {
    display: flex;
    margin-top: 4px;

    p {
      margin: 0;
      font-size: 14px;
      color: $text-color-secondary;
    }

    a {
      color: $text-color-secondary;
      font-size: 14px;
      margin-left: 3px;
    }
  }

  .ClipboardRow {
    margin-bottom: 0;
  }

  .actions {
    display: flex;

    .Button {
      margin-right: 20px;
    }
  }
}