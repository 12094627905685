@import "~styles/variables";

.EmailPreview {
  background-color: $white;
  box-shadow: 0px 0px 8px 2px rgba(173, 173, 173, 0.1);
  max-width: 570px;
  min-width: 570px;

  @media only screen and (max-width: 768px) {
    min-width: unset;
  }

  &-subject {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 12px;

    .label {
      text-transform: uppercase;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  &-main {
    padding: 6px 12px 24px;

    .Logo {
      margin-bottom: 24px;

      img {
        width: 60px;
        height: auto;
      }
    }

    .Title {
      margin-bottom: 12px;
      font-size: 22px;
      font-weight: 600;
    }

    .Text {
      margin-bottom: 20px;
      line-height: 20px;

      &:first-child {
        margin-top: 0;
      }

      img {
        max-width: 500px;
        object-fit: contain;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1.1;
        margin-top: 2.5rem;
        text-wrap: pretty;
      }

      h1,
      h2 {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
      }

      h1 {
        font-size: 1.4rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      h3 {
        font-size: 1.1rem;
      }

      h4,
      h5,
      h6 {
        font-size: 1rem;
      }
    }

    .Variables {
      padding: 20px 0 14px;
      background-color: #f5f5f5;
    }

    .UserPortalCredentials,
    .LicensesList {
      border: 1px solid #f5f5f5;
      border-bottom: none;
      border-top: 5px solid #47c6ef;

      .section-title {
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        padding: 10px 20px 0px;
        text-transform: uppercase;
      }
    }

    .variable-item {
      padding: 0 20px 0px;
      margin-bottom: 6px;

      .value {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
      }

      .label {
        font-size: 12px;
        line-height: 20px;
        color: $text-color-secondary;
      }
    }

    .UserPortalCredentials {
      padding-bottom: 24px;

      .section-title {
        margin-bottom: 16px;
      }
    }

    .LicensesList {

      .section-title {
        margin-bottom: 16px;
      }

      .license {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 2px solid #f5f5f5;
        padding: 0 20px 0px;
      }

      .license-item-wrapper {
        width: 50%;

        .license-item {
          max-width: 170px;
          margin-right: 40px;
          margin-bottom: 10px;

          .value {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
          }

          .label {
            font-size: 12px;
            line-height: 20px;
            color: $text-color-secondary;
          }
        }
      }
    }

    .license-custom-fields {
      width: 100%;
      margin-bottom: 10px;

      .license-custom-fields-title {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        color: #464854;
      }

      .custom-field-item {
        margin-top: 2px;
      }

      .custom-field-name {
        font-size: 12px;
        word-break: break-all;
      }

      .custom-field-divider {
        font-size: 12px;
        margin-right: 5px;
      }

      .custom-field-value {
        font-size: 12px;
        word-break: break-all;
      }
    }
  }
}