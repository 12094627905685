@import '~styles/variables';

.Widget {
  background: #fff;
  border: 1px solid $form-element-border;
  box-sizing: border-box;
  max-width: 300px;
  min-width: 200px;
  padding: 10px;
  margin: 0 10px;

  &:first-of-type {
    margin-left: 0;
  }

  header {
    font-size: 13px;
    font-weight: 500;
  }

  main {
    margin-top: 20px;
    text-align: right;
    font-size: 30px;
    font-weight: 600;
  }
}