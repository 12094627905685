@import "~styles/variables";

.NotificationPolicyForm {

  .form-row {

    .Selector {
      max-width: 100%;
    }

    .TextInput {

      input {
        max-width: 100%;
      }
    }

    .Checkbox {
      margin: 10px 0 0;
    }

    &.product-select {
      margin-bottom: 10px;

      .Label {
        margin-top: 4px;
      }
    }
  }
}