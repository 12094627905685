@import "~styles/variables";

.ChangeCustomerOrderForm {

  &-form {

    .customer-details {

      .existing-customer-selector {

        .Selector {
          max-width: 350px;
        }

        .existing-customer {
          display: flex;
          flex-wrap: wrap;

          .search-selector {
            min-width: 150px;
          }

          .customer-selector {
            .Selector {
              min-width: 350px;
            }
          }
        }
      }

      .buttons-container {
        margin-bottom: 20px;
      }

      .form-row {
        display: flex;
        flex-wrap: wrap;

        .form-column {
          margin-right: 40px;

          .TextInput,
          .NumberInput,
          .PhoneNumberInput {

            input {
              max-width: unset;
              // min-width: unset;
              box-sizing: border-box;
            }
          }

          .PhoneNumberInput {
            min-width: 300px;
          }
        }
      }
    }
  }
}