.HardwareKeys {
  .list-header {
    .main-action {
      display: flex;
      flex-direction: column;

      .read-instruction {
        margin-top: 6px;
        font-size: 13px;
      }
    }
  }
}