@import "~styles/variables";

.AddCompanyFeatureForm {

  .TextInput,
  .NumberInput {

    input {
      max-width: 400px;
    }
  }
}