.ChartTooltip {
  background: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 2px;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.2);

  &-date {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  &-row {
    margin-bottom: 4px;
  }
}