.GenerateReportForm {
  .form-horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .row {

    .DatePicker {
      .date-show-cont {
        align-self: unset;
        justify-content: flex-start;
      }
    }

    .TextInput {
      input {
        max-width: unset;
      }
    }
  }
}