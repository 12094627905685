@import "~styles/variables";

.CouponForm {

  &-form {

    .form-inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    
      @media screen and(max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }
    
      .Subtitle {
        margin: 10px 0;
      }
    
      .TextInput,
      .NumberInput {
    
        input {
          max-width: unset;
          min-width: unset;
          box-sizing : border-box;
        }
      }
    
      .hidden {
        visibility: hidden;
      }
    
      .left {
        flex: 1;
    
        @media screen and(max-width: 1200px) {
          margin-right: 0;
          min-height: unset;
          width: 100%;
        }
    
        .Checkbox {
          margin-top: 0 !important;
        }
    
        .Selector {
          max-width: unset;
        }
    
        .trial-checkbox {
          margin-top: 20px;
        }
      }
    
      .divider {
        display: none;
        margin: 30px 30px 10px;
        background: $form-element-border;
        width: 1px;
    
        @media screen and(max-width: 1200px) {
          display: none;
        }
    
        &.hidden {
          display: block;
          visibility: hidden;
        }
    
        &.active {
          display: block;
    
          @media screen and(max-width: 1200px) {
            display: none;
          }
        }
      }
    
      .right {
        flex: 1;
    
        @media screen and(max-width: 1200px) {
          margin-left: 0;
          min-height: unset;
          width: 100%;
        }
    
        &.hidden {
          visibility: hidden;
        }
    
        .Selector {
          max-width: unset;
        }
    
        .DateInput {
          max-width: unset;
          min-width: unset;
          width: 100%;
    
          input {
            max-width: unset;
            min-width: unset;
          }
        }
    
        .flex-row {
          display: flex;
          flex-wrap: wrap;
    
          @media screen and(max-width: 1200px) {
            align-items: flex-start;
          }
    
          .NumberInput {
            flex-grow: 1;
            margin-right: 10px;
    
            @media screen and(max-width: 1200px) {
              max-width: unset;
              width: 50%;
              margin-right: 0;
            }
    
            input {
              min-width: unset !important;
              margin-right: 10px;
    
              @media screen and(max-width: 1200px) {
                width: 95% !important;
                margin-right: 0;
              }
            }
          }
    
          .Selector {
            flex-grow: 1;
    
            @media screen and(max-width: 1200px) {
              max-width: unset;
              width: 50%;
            }
    
            .selector-inner__control {
              min-height: calc(32px + 2px);
            }
          }
        }
      }
    }
  }
}