@import '~styles/variables';
@import '~styles/helpers';

.NotFound-container {
  @include flexMiddleCenter;
  flex-direction: column;
  padding: 100px 0;

    .Subtitle {
      margin-top: 10px;
    }

    p {
      text-align: center;
      color: $text-color-primary;
    }
}