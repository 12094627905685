.BundleContainer {
}

.bundle-title-container {
  display: flex;
  align-items: center;

  .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px;

    &:hover {
      background-color: #e2e2e2;
      opacity: 1;
    }
  }
}