@import "~styles/variables";

.EditProductForm {

  .second-tab {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }

    .form-row {
      max-width: 300px;

      &.expanded {
        .Label {
          margin-top: 4px;
        }
      }
    }

    .flex-row {
      display: flex;
      flex-wrap: wrap;

      @media screen and(max-width: 1200px) {
        align-items: flex-start;
      }

      .NumberInput {
        flex-grow: 1;
        margin-right: 10px;

        @media screen and(max-width: 1200px) {
          max-width: unset;
          width: 50%;
          margin-right: 0;
        }

        input {
          min-width: unset !important;
          margin-right: 10px;

          @media screen and(max-width: 1200px) {
            width: 95% !important;
            margin-right: 0;
          }
        }
      }

      .Selector {
        flex-grow: 1;

        @media screen and(max-width: 1200px) {
          max-width: unset;
          width: 50%;
        }

        .selector-inner__control {
          min-height: calc(32px + 2px);
        }
      }
    }

    .left {
      flex: 1;

      @media screen and (max-width: 1200px) {
        margin-right: 0;
        min-height: unset;
        width: 100%;
      }

      .NumberInput {
        input {
          box-sizing: border-box;
          max-width: unset;
        }
      }

      .Checkbox {
        margin-top: 10px;
      }

      .Selector {
        max-width: unset;
      }
    }
  }
}

