@import "~styles/variables";

.PhoneNumberInput {
  position: relative;

  .PhoneInputCountryIcon {
    height: auto;
    width: 30px;
    box-shadow: none;
  }

  input {
    height: $form-element-height;
    width: 100%;
    max-width: 250px;
    min-width: 200px;
    border: 1px solid $form-element-border;
    outline: none;
    padding: 0 10px;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    font-family: Arial, sans-serif;

    &:focus {
      border: 1px solid $form-element-border-focus;
    }

    &:disabled {
      background: $form-element-disabled-background;
      border-color: $form-element-disabled-border;
    }
  }

  .PhoneNumberInput-main {
    &.has-error {
      input {
        border-color: $red;
      }
    }
  }
}