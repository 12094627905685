@import '~styles/variables';

.SettingsContainer {

  .Security {
    margin-top: 30px;

    .Button {
      margin-top: 10px !important;
    }

    .description {
      font-size: 14px;
      color: $text-color-secondary;
    }
  }

  .row {

    &.checkboxes {
      margin-top: 14px;
    }

    &.management-key {

      .Button {
        margin: 0;
      }
    }

    .text-input-cont {
      position: relative;
      max-width: 450px;

      .TextInput {
        input {
          max-width: 450px;
        }
      }
    }
  }

  .form-cont {

    .TextInput {
      max-width: 300px;
    }

    .Button {
      margin-top: 20px;
    }
  }
}