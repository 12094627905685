.OrderManagerForm {

  .existing-manager {
    margin: 10px 0 20px;

    .search {
      display: flex;
      align-items: center;

      .search-manager-selector {
        .Selector {
          min-width: 180px;
        }
      }

      .Selector {
        min-width: 350px;
      }
    }
  }
}