@import "~styles/variables";

.Label {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-top: 14px;

  .Label-elem {
    font-weight: 500;
    font-size: 13px;
    color: $text-color-secondary;
    text-align: left;
    margin-right: 5px;
  }

  .Tooltip {
    position: relative;

    span {
      position: absolute;
      top: -12px;

      svg {
        cursor: help;
        vertical-align: super;
      }
    }
  }
}
