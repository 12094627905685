@import "~styles/variables";

.CustomerLabelsSelector {
  position: relative;

  .CustomerLabelsSelector-option,
  .CustomerLabelsSelector-value {
    display: flex;
    align-items: center;

    .option-color {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-right: 5px;
    }
  }

  .CustomerLabelsSelector-option {
    width: 100%;
    height: 100%;
    padding: 10px 5px;
  }

  .CustomerLabelsSelector-value {
    padding-left: 2px;
  }

  .selector-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;
    left: 10px;
    top: 7px;
    z-index: 9;
    pointer-events: none;

    svg {
      margin-right: 10px;
    }
  }

  .selector-inner__control {

    .selector-inner__multi-value {
      display: none;
    }

    .selector-inner__input {
      display: block !important;
      position: relative;

      input {
        width: 100% !important;
        cursor: pointer;
        color: transparent;
      }
    }
  }

  .selector-inner__option {
    display: flex;
    align-items: center;
    padding: 0 7px;

    &:hover {
      background-color: $option-hover-background !important;
    }

    &--is-selected {
      background-color: $option-selected-background !important;

      &:hover {
        background-color: $option-selected-background !important;
      }
    }
  }
}