@import "~styles/variables";

.product-version-form {
  .Selector {
    max-width: 300px;
  }

  .TextInput {
    input {
      min-width: unset;
    }
  }

  .DateInput {
    width: 100%;

    @media screen and (max-width: 850px) {
      min-width: unset;

      input {
        min-width: unset;
      }
    }
  }

  .form-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  
    @media screen and(max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  
    .TextInput,
    .NumberInput {
  
      input {
        max-width: unset;
        min-width: unset;
        box-sizing : border-box;
      }
    }
  
    .hidden {
      visibility: hidden;
    }
  
    .left {
      flex: 1;
  
      @media screen and(max-width: 1200px) {
        margin-right: 0;
        min-height: unset;
        width: 100%;
      }
  
      .Checkbox {
        margin-top: 10px !important;
      }
  
      .Selector {
        max-width: unset;
      }
  
      .trial-checkbox {
        margin-top: 20px;
      }
    }
  
    .divider {
      display: none;
      margin: 30px 30px 10px;
      background: $form-element-border;
      width: 1px;
  
      @media screen and(max-width: 1200px) {
        display: none;
      }
  
      &.hidden {
        display: block;
        visibility: hidden;
      }
  
      &.active {
        display: block;
  
        @media screen and(max-width: 1200px) {
          display: none;
        }
      }
    }
  
    .right {
      flex: 1;
  
      @media screen and(max-width: 1200px) {
        margin-left: 0;
        min-height: unset;
        width: 100%;
      }
  
      &.hidden {
        visibility: hidden;
      }
  
      .Selector {
        max-width: unset;
      }
  
      .DateInput {
        max-width: unset;
        min-width: unset;
        width: 100%;
  
        input {
          max-width: unset;
          min-width: unset;
        }
      }
    }
  }
}