.LicenseDetailsTable {

  .license-policy-content {
    display: flex;
    align-items: center;

    .policy-name {
      margin-right: 20px;
    }
  }

  .total-consumptions-cell {
    display: flex;
    align-items: center;

    .value {
      margin-right: 20px;
    }
  }

  .Logs {
    margin-top: 20px;
  }
}