.user-title-container {

  .labels {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .Button {
      margin-bottom: 0;
    }
  }

  .user-title {
    display: flex;
    align-items: center;

    .clipboard-btn {
      display: inline-flex;
      align-items: center;
      z-index: 2;
      background-color: transparent;
      opacity: 0.5;
      -webkit-appearance: none;
      border: none;
      width: 34x;
      cursor: pointer;
      outline: none;
      margin-left: 10px;

      &:hover {
        background-color: #e2e2e2;
        opacity: 1;
      }
    }
  }
}

.UserContainer {

  &-permissions {
    background: #fff;
    border: 1px solid #E3E3E3;
    padding: 10px 15px;

    .Button {
      margin: 0 20px 0 0;
    }

    .permissions-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 50%;
      padding-inline-start: 0;

      .permission-item {
        list-style-type: square;
        font-size: 14px;
        font-weight: 400;
        width: 40%;
        margin-bottom: 5px;
        margin-left: 20px;
      }
    }
  }
}