@import "~styles/variables";

.LicenseUsersActionMenu {
  position: relative;

  .header {
    margin-top: 30px;

    .text {
      font-weight: 500;
      font-size: 14px;
    }
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 10px 0 30px 0;

    li {
      padding: 10px 0 !important;
      border-bottom: 1px solid #eaeaea;
  
      &:hover {
        background-color: $option-hover-background;
      }
  
      button {
        text-align: start;
        width: 100%;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        .icon-wrapper {
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg {
          margin-right: 10px;
          margin-top: 2px;
        }
      }
    }
  }
}