@import "~styles/variables";

.ActivityAnalytics {
  padding-bottom: 50px;
  
  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .mrl {

    .mrl-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
    }

    .ProgressBar {
      margin-bottom: 20px;
    }
  }
}