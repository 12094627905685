.AnalyticsReports {

    .AnalyticsReports-selector {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .section-row {
            min-width: 300px;
            margin-right: 40px;
            margin-bottom: 20px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .Label {
            margin-top: 0;
        }
    }

    .row {
        margin-bottom: 30px;
    }
}