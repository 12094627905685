@import '~styles/variables';

.Notification {
  font-family: $font-family;
  color: $text-color-primary;

  .Notification-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 6px;
  }
  .Notification-body {
    font-size: 13px;
    color: $text-color-secondary;
  }
}

.Notification-container {
  position: relative;
  &.Toastify__toast {
    padding: 15px 15px 15px 15px;
    border-left: 10px solid #fff;
  }

  &.Toastify__toast--default {

  }

  &.Toastify__toast--success {
    background: #fff;
    border-color: $green;
  }

  &.Toastify__toast--error {
    background: #fff;
    border-color: $red;
  }

  &.Toastify__toast--warning {
    background: #fff;
    border-color: $yellow;
  }

  &.Toastify__toast--info {
    background: #fff;
    border-color: $blue;
  }

  .toast-dismiss {
    position: absolute;
    top: 10px;
    right: 0;
    background: transparent;
    border: none;
    font-size: 5px;
    cursor: pointer;
    opacity: 0.5;
    font-weight: 600;
    outline: 0;

    &:hover {
      opacity: 1;
    }
  }
}