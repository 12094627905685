.ClipboardRow {

  .text-input-cont {
    position: relative;
    max-width: 450px;

    .TextInput {
      input {
        max-width: 450px;
      }
    }

    .TextArea {
      textarea {
        padding-right: 60px;
        min-height: 80px;
      }
    }

    .clipboard-btn {
      position: absolute;
      right: 1px;
      top: 1px;
      z-index: 2;
      -webkit-appearance: none;
      background-color: #d8d8d8;
      height: 93%;
      bottom: 1px;
      border: none;
      width: 34x;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;

      &:hover {
        background-color: #e2e2e2;
      }
    }
  }
}