.LicenseUsers {

  .license-user-tab-actions {

    .left {

      .Button {
        margin-right: 20px;
      }
    }
  }
}