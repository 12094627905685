@import '~styles/variables';

.NoGraphData {
  border: 1px solid #e5e9ea;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;

  &-label {
    position: absolute;
    top: 10px;
    left: 10px;
    
    .title {
      font-weight: 500;
      font-size: 13px;
    }

    .subtitle {
      font-size: 12px;
      color: $text-color-secondary;
    }
  }

  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
  }

  .text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }

  .description {
    font-size: 12px;
  }
}