@import "~styles/variables";
@import "~styles/helpers";

.DirtyFormAlert {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#000, .5);
  z-index: 9999999;
  color: $text-color-primary;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 0 50px;

  &-inner {
    position: relative;
    background-color: $white;
    box-shadow: 1px 1px 10px rgba(#000, 0.2);
    width: 30vw;
    max-width: 400px;
    min-width: 300px;
    margin-top: 75px;

    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: unset;
    }

    .DirtyFormAlert-header {
      padding: 20px 30px 0px 20px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
    }

    .DirtyFormAlert-body {
      padding: 0 20px 20px;
      background-color: $white;
    }

    .DirtyFormAlert-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      background-color: lighten($gray, 50%);
      padding: 5px 20px;

      &.hidden {
        display: none;
      }

      button {
        margin-right: 10px;
      }
    }

    .DirtyFormAlert-dismiss {
      position: absolute;
      top: 10px;
      right: 0;
      background: transparent;
      border: none;
      font-size: 15px;
      cursor: pointer;
      opacity: 0.5;
      font-weight: 600;
      outline: 0;
      margin-right: 0 !important;

      &:hover {
        opacity: 1;
      }
    }
  }
}