@import "~styles/variables";
@import "~styles/helpers";

$button-width: 30px;

.DateInput {
  position: relative;
  max-width: $form-element-max-width;
  min-width: 300px;
  width: 100%;

  .Dropdown {
    box-shadow: none;
    background: transparent;
  }

  &-button {
    @include appearance(none);
    width: $button-width;
    height: calc(#{$form-element-height} - 2px);
    position: absolute;
    top: 1px;
    right: 2px;
    border: 0;
    outline: 0;
    opacity: 0.5;
    background: transparent;

    &:hover {
      opacity: 1;
    }
  }

  input {
    height: $form-element-height;
    width: 100%;
    max-width: $form-element-max-width;
    min-width: 300px;
    box-sizing: border-box;
    border: 1px solid $form-element-border;
    outline: none;
    padding: 0 30px 0 10px;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;

    &:focus {
      border: 1px solid $form-element-border-focus;
    }

    &:disabled {
      background: $form-element-disabled-background;
      border-color: $form-element-disabled-border;
    }

    &.has-error {
      border-color: $red;
    }
  }

  &-popup {
    position: absolute;
    right: 0;
    z-index: 10;
    box-shadow: $shadow;

    .DayPicker {
      border-radius: 0;
    }
  }
}