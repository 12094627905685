@import "~styles/variables";

.BatchLicenseAssign {

  &-filter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    .filter-field {
      width: 48%;
    }
  }

  &-main {

    .unassigned-count {
      font-weight: 500;

      span {
        color: $red;
      }
    }

    .MultiEmailInput {
      max-width: 100%;
    }

    .warning-notice {
      font-size: 14px;
      margin: 20px 0;
    }
  }
}