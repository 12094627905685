@import "~styles/variables";

.EditLicenseFormTitle {
  display: flex;
  align-items: center;

  .confirm-btn {
    margin-left: auto;
  }
}

.EditLicenseForm {

  .confirm-btn {
    
    .Button {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  &-main {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
  }

  .sections-wrapper {
    width: 40%;

    &:first-of-type {
      margin-right: 10%;
    }
  }

  section {
    margin-bottom: 30px;

    .section-header {
      font-weight: 600;
      margin-bottom: 14px;
      color: $text-color-primary;
      font-size: 18px;
  
      &.first {
        margin-top: 0;
      }

      &.license-usage {
        margin-bottom: 4px;
      }
    }

    .section-row {

      .Notice {
        max-width: 380px;
      }

      .InputErrorMessage {
        max-width: 400px;
      }

      .DateInput {
        max-width: 400px;
        min-width: unset;
        width: 100%;
  
        input {
          max-width: unset;
          min-width: unset;
        }
      }

      .TextInput,
      .NumberInput {
        
        input {
          max-width: 400px;
        }
      }

      .Checkbox {
        margin-top: 10px;
      }

      &.initial-checkbox {
        .Checkbox {
          margin-top: 0;
        }
      }

      &.max-simult-users {
        margin-bottom: 14px;
        
        .Label {
          margin-top: 5px;
        }
      }

      &.time-limited-options {
        margin-bottom: 10px;
      }

      &.overage-options {
        .radio-btns-overages {
          margin-top: -5px;
          margin-bottom: 10px;
        }
      }

      &.consumption-period-select {
        margin-bottom: 10px;

        .Label {
          margin-top: 4px;
        }
      }

      &.max-activations {
        .Checkbox {
          margin-top: 0;
        }
      
        .Label {
          margin-top: 7px;
        }
      }

      &.row-max-license-users-checkbox {
        .Checkbox {
          margin-top: 4px;
          margin-bottom: 10px;
        }
      }

      .flex-row {
        display: flex;
        flex-wrap: wrap;
        max-width: 400px;
  
        @media screen and(max-width: 1200px) {
          align-items: flex-start;
        }
  
        .NumberInput {
          flex-grow: 1;
          margin-right: 10px;
  
          @media screen and(max-width: 1200px) {
            max-width: unset;
            width: 50%;
            margin-right: 0;
          }
  
          input {
            min-width: unset !important;
            margin-right: 10px;
  
            @media screen and(max-width: 1200px) {
              width: 95% !important;
              margin-right: 0;
            }
          }
        }
  
        .Selector {
          flex-grow: 1;
  
          @media screen and(max-width: 1200px) {
            max-width: unset;
            width: 50%;
          }
  
          .selector-inner__control {
            min-height: calc(32px + 2px);
          }
        }
      }
    }
  }
}

// this holds other popup style in check = DONT DELETE!!!
.form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and(max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }

  .Subtitle {
    margin: 10px 0;
  }

  .TextInput,
  .NumberInput {

    input {
      max-width: unset;
      min-width: unset;
      box-sizing : border-box;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .left {
    flex: 1;

    @media screen and(max-width: 1200px) {
      margin-right: 0;
      min-height: unset;
      width: 100%;
    }

    .Checkbox {
      margin-top: 0 !important;
    }

    .Selector {
      max-width: unset;
    }

    .trial-checkbox {
      margin-top: 20px;
    }

    .trialConfig {
      .radio-btns {
        margin-bottom: 10px;

        .RadioBtn:nth-of-type(1) {
          margin-top: 5px;
        }
      }
    }

    .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%;

      input {
        max-width: unset;
        min-width: unset;
      }
    }
  }

  .divider {
    display: none;
    margin: 30px 30px 10px;
    background: $form-element-border;
    width: 1px;

    @media screen and(max-width: 1200px) {
      display: none;
    }

    &.hidden {
      display: block;
      visibility: hidden;
    }

    &.active {
      display: block;

      @media screen and(max-width: 1200px) {
        display: none;
      }
    }
  }

  .right {
    flex: 1;

    @media screen and(max-width: 1200px) {
      margin-left: 0;
      min-height: unset;
      width: 100%;
    }

    .floating-config {
      margin: 10px 0;

      .Label {
        margin-top: 0;
      }
    }

    .radio-btns {
      margin-top: 20px;

      &.radio-btns-overages {
        margin-top: -5px;
      }

      .RadioBtn {
        &:nth-of-type(1) {
          margin-top: 10px;
        }

        label {
          font-weight: 500;
          font-size: 13px;
          color: #525462;
        }
      }
    }

    &.hidden {
      visibility: hidden;
    }

    .Selector {
      max-width: unset;
    }

    .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%;

      input {
        max-width: unset;
        min-width: unset;
      }
    }

    .flex-row {
      display: flex;
      flex-wrap: wrap;

      @media screen and(max-width: 1200px) {
        align-items: flex-start;
      }

      .NumberInput {
        flex-grow: 1;
        margin-right: 10px;

        @media screen and(max-width: 1200px) {
          max-width: unset;
          width: 50%;
          margin-right: 0;
        }

        input {
          min-width: unset !important;
          margin-right: 10px;

          @media screen and(max-width: 1200px) {
            width: 95% !important;
            margin-right: 0;
          }
        }
      }

      .Selector {
        flex-grow: 1;

        @media screen and(max-width: 1200px) {
          max-width: unset;
          width: 50%;
        }

        .selector-inner__control {
          min-height: calc(32px + 2px);
        }
      }
    }
  }
}
