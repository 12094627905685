@import "~styles/variables";

.MonthlyRateLimitBar {
  &-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  .ProgressBar {
    margin-bottom: 20px;
  }
}