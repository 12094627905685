@import "~styles/variables";

.CompanyList {

  .Page {
    width: 100%;
    margin-left: 0;

    .list-header {
      margin-bottom: 10px;
    }
  }
}