@import '~styles/variables';
@import '~styles/helpers';

.Page {
  height: calc(100vh - #{$TopHeader-height});
  width: calc(100% - #{$Sidebar-width});
  margin-top: $TopHeader-height;
  margin-left: $Sidebar-width;
  float: left;
  overflow-y: auto;
  position: relative;

  .Page-header {
    padding: 20px 30px;
    box-sizing: border-box;
  }

  .Page-top {
    height: 20px;
  }

  .Page-title {
    color: $text-color-primary;
    font-size: 30px;
    font-weight: 500;
    margin: 15px 0 10px;
    word-break: break-word;
  }

  .Page-description {
    color: $text-color-secondary;
    font-size: 14px;
  }

  .Page-content {
    padding: 0 30px 50px;
    @include animate(fadeInPage, 100ms, ease-in, 0);
  }

  &.Sidebar-size-sm {
    width: calc(100% - #{$Sidebar-width-sm});
    margin-left: $Sidebar-width-sm;
  }

}

@include keyframe(fadeInPage) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}