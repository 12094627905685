@import "~styles/variables";

.CustomFormTab {
  display: flex;
  align-items: center;
  position: relative;

  &.error {
    span {
      color: $red;
    }

    svg {
      position: absolute;
      right: -20px;
    }
  }
}