@import "~styles/variables";

.Option {
  display: flex;
  align-items: center;

  &:hover {
    background: $option-hover-background;
  }

  &--selected {
    background: $option-selected-background;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: transparent;
  }

  &-LockIcon {
    margin: 0 0 0 5px;
  }
}