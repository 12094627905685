@import "~styles/variables";

.ProductFeaturesEditor {
    margin-top: 10px;

    ol {
        padding-inline-start: 16px;
        margin: 0;
    }

    .feature-row {
        margin: 0 0 20px;
        background: #fff;
        border: 1px solid $form-element-border;
        border-left: 4px solid $gray;
        padding: 10px 15px;
        font-size: 13px;
        color: $text-color-primary;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .product-name {
            font-size: 14px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;

            .name {
                margin-right: 6px;
            }

            .code {
                font-size: 13px;
                font-weight: 500;
                margin-right: 20px;
            }

            .floating-pill {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 80px;
                // max-width: 80px;
                padding: 3px 6px;
                font-size: 12px;
                margin-left: 20px;
                border-width: 1px;
                border-style: solid;
                background: rgba($orange, .15);
                border-color: rgba($orange, .35);
                color: darken($orange, 25%);
            }
        }

        .actions {
            .Button {
                margin-bottom: 0;
                margin-left: 20px;
            }
        }
    }

    .details {
        display: flex;

        .label {
            width: 30%;
            min-width: 100px;
            word-break: break-word;
            box-sizing: border-box;
            padding: 6px 2px;
            color: $text-color-primary;
            font-weight: 500;
        }

        .value {
            width: 70%;
            word-break: break-word;
            box-sizing: border-box;
            padding: 6px 2px;
            color: $text-color-secondary;
            font-weight: 400;
        }
    }
}