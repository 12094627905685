@import "~styles/variables";
@import "~styles/helpers";

.AddSSOProviderForm {

  .docs-link {
    display: flex;
    margin: 10px 0;

    p {
      margin: 0;
      font-size: 14px;
      color: $text-color-secondary;
    }

    a {
      color: $text-color-secondary;
      font-size: 14px;
      margin-left: 3px;
    }
  }

  .google-form,
  .saml-form {
    display: flex;
    flex-direction: column;

    .TextInput {
      input {
        max-width: 400px;
      }
    }

    .FileUploader {
      max-width: 400px;

      .dzu-dropzone {
        min-height: 90px;
        justify-content: center;

        .dzu-previewContainer {
          border-bottom: none;
        }
      }

      .dzu-submitButtonContainer {
        display: none;
      }
    }
  }

  .create-user-config {
    margin-top: 20px;
  }
}