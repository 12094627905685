@import "~styles/variables";

.ProductReactivation-popup {
  .row {
    margin-bottom: 5px;

    .label {
      margin-right: 6px;
      font-size: 14px;
      color: $text-color-secondary;
    }

    .value {
      font-weight: 500;
    }
  }
}