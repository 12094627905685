@import "~styles/variables";

.CustomFieldsEditableTable {
  margin-top: 30px;

  .List {
    .InputErrorMessage {
      display: none;
    }

    .rt-noData {
      display: none;
    }

    .TextInput {

      input {
        max-width: unset;
      }
    }
  }
}