@import '~styles/variables';

.DescriptionCard {
  margin: 0 0 20px;
  background: #fff;
  border: 1px solid $form-element-border;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;

  &.sm {
    width: 50%;
  }

  &.success {
    background: rgba($green, 0.1);
  }

  &.info {
    background: rgba($blue, 0.1);
  }

  &.error {
    background: rgba($red, 0.1);
  }

  &.warning {
    background: rgba($orange, 0.1);
  }

  & > div {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #aaa;

    &:last-of-type {
      border: 0;
    }
  }
}