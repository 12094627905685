@import "~styles/variables";

.SelectedFeaturesTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .SFTag {
    cursor: default;
    margin-right: 12px;
    margin-bottom: 5px;
    padding: 2px 6px;
    border: 1px solid #f1f1f1;
    border-left: 2px solid $blue;
    background: #f0f6fb;

    &-inner {
      display: flex;

      span {
        font-size: 12px;
      }

      svg {
        margin-left: 5px;
        flex-shrink: 0;
      }
    }
  }
}

.sf-tags-tooltip {

  .rc-tooltip-inner {

  }

  &-inner {
    display: flex;
    flex-direction: column;
  }
}