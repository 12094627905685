@import "~styles/variables";

.FeatureTypePill {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    max-width: 80px;
    padding: 3px 6px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;

    &-activation {
        background: rgba($blue, .15);
        border-color: rgba($blue, .35);
        color: darken($blue, 25%);
    }

    &-consumption {
        background: rgba($green, .15);
        border-color: rgba($green, .35);
        color: darken($green, 25%);
    }
}