.TabContent-not-available {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;

  &-description {
    margin: 20px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  a {
    text-decoration: none;
    background: #2e86de;
    border: 1px solid #1f72c4;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    height: 44px;
    padding: 0 40px;
    margin: 10px 0;
    text-align: center;
    color: #fff;
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.9;
    }
  }
}