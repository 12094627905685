@import "~styles/variables";

.EditLicenseUser {
  .form-inner {
    display: flex;
    flex-direction: column;
  
    @media screen and(max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .Subtitle {
      margin: 10px 0;
    }

    .form-row {
      width: 100%;
    }
  
    .TextInput,
    .NumberInput,
    .PhoneNumberInput {
  
      input {
        max-width: unset;
        min-width: unset;
        box-sizing : border-box;
      }
    }
  
    .hidden {
      visibility: hidden;
    }
  }
}