.ProductBundleForm {

  .form-row {
    max-width: 300px;

    .Selector {
      max-width: 300px;
    }

    .products-list {

      ul {
        margin: 10px 0 0 0;
        list-style-type: square;
        padding-inline-start: 14px;

        li {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 6px;

          span {

            &.code {
              margin-left: 6px;
              font-weight: normal;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}