@import '~styles/helpers';
@import '~styles/variables';

a {
  color: inherit;
}

.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;

  & > div {
    display: flex;

    &:first-of-type {
      .Button {
        margin-right: 10px;
        margin-bottom: 0;
      }

      .CheckboxSelector.Selector {
        width: 160px;
        margin-left: 20px;
      }
    }

    &:last-of-type {
      .Button {
        margin-right: 10px;
        margin-top: 0;
        margin-bottom: 0;
        flex-shrink: 0;

        // &:last-of-type {
        //   margin-right: 0;
        // }
      }
    }

    form {
      display: flex;
      align-items: center;
      position: relative;

      button[type=submit] {
        @include appearance(none);
        @include flexMiddleCenter;
        border: 0;
        border-radius: 0 3px 3px 0;
        margin: 0;
        padding: 0 20px;
        position: relative;
        height: $form-element-height;
        cursor: pointer;
        outline: 0;
        z-index: 2;
      }

      .TextInput {

        input {
          padding: 0 10px 0 15px;
          width: 250px;
          min-width: 350px;
        }
        .InputErrorMessage {
          display: none;
        }
      }

      button[type=button] {
        @include appearance(none);
        @include flexMiddleCenter;
        cursor: pointer;
        position: absolute;
        right: 70px;
        // margin-left: -30px;
        width: 40px;
        height: $form-element-height;
        font-size: 20px;
        text-align: center;
        border: 0;
        background: transparent;
        outline: 0;
        opacity: 0.5;
        z-index: 1;

        &:hover {
          opacity: 1;
        }
      }
    }

    .Selector {
      width: 150px;
      font-size: 12px;
      font-weight: 500;

      .selector-inner__control {
        min-height: 34px;
      }
    }

    .search-selector {

      .Selector {
        width: 210px !important;
      }
    }

    .columns-display-select {
      .Selector {
        width: 190px !important;
      }
    }
  }
}

::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  min-height: 28px;
  padding: 100px 0 0;
  box-shadow: inset 1px 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.07);
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}