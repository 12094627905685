@import "~styles/variables";

.RescheduleUpdateForm {

  .scheduled-date-container {

    .Label {
      margin-bottom: 10px;
    }
  }
}