.EndpointForm {

  &-input-row {

    .TextInput {
      input {
        max-width: 100%;
      }
    }
  }
}