@import "~styles/variables";

.EmailConfiguration {

  &-sender {
    margin-bottom: 20px;

    .Label {
      margin-top: 0;
    }

    .email {
      font-size: 14px;
    }
  }

  &-title {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .Notice {
    margin-top: 0;
  }

  .test-email {
    margin-bottom: 0px;

    .Label {
      margin-top: 0;
    }

    .test-email-input-wrapper {
      display: flex;
      align-items: center;

      .Button {
        margin: 0;
      }

      .TextInput {
        width: 100%;

        input {
          max-width: 100%;
        }
      }
    }
  }

  .Wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .Configurator {
    margin-bottom: 20px;
    min-width: 540px;
    max-width: 540px;
    padding-right: 40px;

    .event-selector {
      .Label {
        margin-top: 0;
      }
    }

    .TextInput {

      input {
        max-width: 100%;
      }
    }

    .TextArea {
      textarea {
        max-width: 100%;
        min-height: 150px;
      }
    }

    .Selector {
      max-width: 100%;
    }

    .form-row {
      &.checkbox {
        margin: 14px 0 20px;
      }
    }

    .buttons-container {
      margin-top: 30px;

      .Button {
        margin-right: 40px;
      }
    }
  }
}