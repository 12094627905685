.TrialOptions {

  .Label {
    margin-top: 5px;
  }

  .flex-row {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
  }

  .NumberInput {
    max-width: 140px;
    margin-right: 10px;

    input {
      max-width: 140px;
      min-width: 140px;
    }
  }

  .Selector {
    flex-grow: 1;

    .selector-inner__control {
      min-height: calc(32px + 2px);
    }
  }
}