@import "~styles/variables";
@import "~styles/helpers";

.LicenseManagerPage {

  .Tabs {
    .react-tabs__tab-list {
      margin-bottom: 0;
    }

    .react-tabs__tab-panel {
      padding-top: 0;
    }
  }

  &-permissions {

    .permission-actions {
      display: flex;
      align-items: flex-end;

      .Button {
        margin-bottom: 4px;
      }
    }

    .DescriptionTable {
      margin-top: 3px;
    }
  }


  &-products-list {

    .Label {
      .Label-elem {
        font-size: 15px;
        color: $text-color-primary;
        font-weight: 600;
      }
    }

    .List {
      margin-bottom: 20px;
    }

    ol {
      padding-inline-start: 0;
      margin: 0;
    }
  }

  &-products {
    display: flex;
    align-items: center;
  }

  .checkbox-selector {
    min-width: 300px;
    max-width: 300px;
    margin-right: 10px;
    margin-bottom: 3px;
  }
}