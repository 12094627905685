.SSOForm {

  .row {

    .TextInput {
      input {
        max-width: unset;
      }
    }
  }
}