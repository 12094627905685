@import "~styles/variables";
@import "~styles/helpers";

.ConfirmationPopup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#000, .5);
  z-index: 99;
  color: $text-color-primary;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 50px 0;

  &-inner {
    position: relative;
    background-color: $white;
    box-shadow: 1px 1px 10px rgba(#000, 0.2);
    width: 30vw;
    max-width: 500px;
    min-width: 400px;

    @media screen and (max-width: 800px) {
      width: 100%;
      max-width: unset;
    }

    .ConfirmationPopup-header {
      padding: 20px 30px 20px 20px;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 600;
    }

    .ConfirmationPopup-body {
      padding: 0 20px 20px;
      background-color: $white;
      line-height: 1.4;
      font-size: 15px;
    }

    .ConfirmationPopup-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      background-color: lighten($gray, 50%);
      padding: 5px 20px;

      &.hidden {
        display: none;
      }

      button {
        margin-right: 10px;
      }
    }

    .ConfirmationPopup-dismiss {
      position: absolute;
      top: 10px;
      right: 0;
      background: transparent;
      border: none;
      font-size: 15px;
      cursor: pointer;
      opacity: 0.5;
      font-weight: 600;
      outline: 0;
      margin-right: 0 !important;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-warning {
    padding-top: 20px;

    .ConfirmationPopup-inner {
      min-width: 300px;
      max-width: 400px;

      .ConfirmationPopup-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        svg {
          margin-bottom: 10px;
        }
      }

      .ConfirmationPopup-body {
        text-align: center;
      }
    }
  }
}