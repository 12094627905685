@import "~styles/variables";

.PaymentMethodForm {
  .form-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    & > div {
      flex: 1;

      &.form-row-column {
        width: 48%;
        flex: none;

        &.cardholder {
          .Label {
            margin-top: 9px;
          }
        }
  
        .TextInput {
          min-width: unset;
          width: 100%;
          
          input {
            min-width: unset;
            max-width: unset;
            width: 100%;
          }
        }
      }
    } 


    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;

      .form-row-column {
        width: 100%;

        input {
          max-width: unset;
        }
      }
    }
  }

  .StripeInput {
    height: 34px;
    width: 100%;
    // max-width: 300px;
    // min-width: 300px;
    outline: none;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    
    .StripeElement {
      padding: 0 10px;
      border: 1px solid $form-element-border;

      &--focus {
        border: 1px solid $form-element-border-focus;
      }
    }
  }

  .extra-information {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;

    .stripe-info {
      display: flex;
      align-items: center;
      margin-top: 5px;

      img {
        height: 25px;
      }
    }
  }
}