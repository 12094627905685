@import '~styles/variables';

.PermissionCheck {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .Subtitle {
    text-align: center;
  }

  &-main {
    display: flex;

    .label {
      margin-right: 10px;
      font-size: 14px;
    }

    .value {
      font-size: 14px;
      color: $red;
    }
  }

  &-image {
    svg {
      max-width: 300px;
      width: 100%;
      height: auto;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }

  &-support {
    font-size: 14px;
  }
}