@import "~styles/variables";
@import "~styles/helpers";

.FileUploader {
  max-width: 600px;

  .progress-bar {
    position: relative;
    height: 5px;
    width: 100%;
    max-width: 700px;
    background-color: $background;
    border-radius: 0;
  
    &-fill {
      background: $blue;
      height: 100%;
      transition: width .2s ease-in;
      border-radius: 0;
    }
  }

  .dzu-dropzone {
    background: $white;
    box-shadow: inset 2px 2px 6px #d9d9d9, inset -2px -2px 6px #ffffff, inset 0px 0px 2px #d9d9d9, inset 0px 0px 0px #ffffff;
    border: none;
    overflow: auto;
    max-width: 600px;
    margin: 0;
    padding: 0 20px;
    border-radius: 0;

    &.dzu-dropzoneActive {
      background-color: #e9f5e9;
    }

    .dzu-inputLabel {
      font-family: inherit;
      color: inherit;
      font-weight: normal;
      font-size: 16px;

      .InputContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-weight: 600;
          margin-bottom: 5px;
        }

        .description {
          font-size: 12px;
          color: $text-color-secondary;

          span {
            margin: 0 3px;
          }
        }
      }
    }

    .dzu-previewContainer {
      padding: 0;

      img {
        border-radius: 0;
      }
    }

    .dzu-submitButtonContainer {
      display: flex;
      flex-direction: column;
      margin: 5px 0 0px;
      width: 100%;
      padding: 0;
    }

    .dzu-submitButton {
      @include hasFocus;
      position: relative;
      touch-action: manipulation;
      user-select: none;
      margin: 10px 0;
      display: inline-flex;
      align-items: center;
      justify-content: space-evenly;
      border: 1px solid transparent;
      color: $white;
      text-align: center;
      font-size: 16px;
      color: #fff;
      outline: 0;
      cursor: pointer;
      transition: $default-transition;
      background: $blue;
      border: 1px solid darken($blue, 8);
      max-width: 120px;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 400;
      height: 24px;
      padding: 0 20px;

      &:hover {
        opacity: 0.9;
      }
    
      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    
        &:hover {
          opacity: 0.3;
        }
      }
    }
  }
}