@import "~styles/variables";
@import "~styles/helpers";

.CustomersList {

  .list-header {
    margin-bottom: 0;
  }

  &-filters {
    margin-bottom: 10px;

    .AdvancedSearch-btn {
      @include appearance(none);
      font-size: 14px;
      color: $blue;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      outline: none;
      margin-top: 10px;
      padding: 0;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .AdvancedSearch-wrapper {
      overflow: visible;
      transition: all .4s cubic-bezier(0.25, 1, 0.5, 1);
      @include animate(visibleOverflow, 400ms, ease-in, 0);
      margin-top: 5px;

      &.hide {
        overflow: hidden;
        padding-bottom: 0px;
        @include animate(hiddenOverflow, 10ms, ease-in, 0);
        margin-top: 0;
      }
    }

    .AdvancedSearch {
      display: block;
      background-color: $white;
      border: 1px solid $form-element-border;
      margin-top: 3px;
      max-height: 500px;
      opacity: 1;
      // transform: translateY(0);
      transition: all .4s cubic-bezier(0.25, 1, 0.5, 1);
    
      &.hide {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-230px);
      }
    
      &-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    
        .filter-section {
          width: 48%;
          padding: 0 0 0 20px;

          .CheckboxSelector {
            max-width: 200px;
          }
        }
      }
    
      &-actions {
        margin-top: 20px;
        background-color: #f0f0f0;
        width: 100%;
        padding: 5px 0 5px 20px;

        .Button {
          margin-right: 40px;
          margin-top: 10px;
        }
      }
    }

    .Filters-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
    }
  }

  &-labels {

    .list-header {
      margin-bottom: 10px;
    }

    .List {
      .rt-tr {
        cursor: initial !important;
      }
    }
  }
}