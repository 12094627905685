.ProductFeatures {

    &-activity {
        margin-top: 50px;
    }

    .floating-slots-btn {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        span {
            margin-right: 10px;
        }
    }

    .total-consumptions-cell {
        display: flex;
        align-items: center;

        .value {
            margin-right: 10px;
        }

        .Button {
            padding: 0;
        }
    }
}