.LogoUpload {

  .logo-upload-container {

    .logo-upload-wrapper {
      display: flex;

      .existing-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #eaeaea;
        padding: 10px;

        img {
          width: 100px;
          height: auto;
          max-height: 100px;
          object-fit: contain;
        }
      }

      .logo-uploader {
        flex-grow: 1;

        .FileUploader {
          // max-width: 600px;
          
          .dzu-dropzone {
            min-height: 124px;
          }
        }
      }
    }
  }
}