@import "~styles/variables";

$option-selected-background: lighten($blue, 30%);

.Selector {
  max-width: 400px;
  height: $form-element-height;
  font-size: 14px;
  position: relative;

  .Spinner {
    position: absolute;
    right: 31px;
    top: 6px;
  }

  .selector-inner__control {
    border-color: $form-element-border;
    cursor: pointer;
    border-radius: 0;
    min-height: $form-element-height;

    &:hover {
      border-color: $form-element-border-focus;
    }

    &.selector-inner__control--is-focused {
      border-color: $form-element-border-focus;
      box-shadow: none;
    }

    .selector-inner__multi-value {
      background-color: $option-selected-background;
    }

    .selector-inner__indicators {

      .selector-inner__clear-indicator {
        // display: none;
      }

      .selector-inner__indicator-separator {
        display: none;
      }

      .selector-inner__indicator {
        // &::after {
        //   content: "";
        //   display: block;
        //   border-top: 5px solid $text-color-primary;
        //   border-left: 5px solid transparent;
        //   border-right: 5px solid transparent;
        // }

        svg {
          // display: none;
          color: #464854;
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .selector-inner__menu {
    margin-top: 0;
    border-radius: 0;
    border: 1px solid $form-element-border-focus;
    box-shadow: none;
    z-index: 9;

    .selector-inner__menu-list {
      max-height: 320px;
    }

    .selector-inner__option {
      color: $text-color-primary;
      cursor: pointer;
      background-color: transparent;
      
      &.selector-inner__option--is-focused {
        background-color: $form-element-border;
      }

      &.selector-inner__option--is-disabled {
        cursor: not-allowed;

        &:hover {
          background: transparent;
        }
      }
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
