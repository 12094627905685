.WebhookPage {

  .section-heading {
    margin: 20px 0 10px;
    font-weight: 500;
  }

  .subheading {
    font-weight: 500;
    font-size: 15px;
  }

  .events-list {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0;

    .event-item {
      list-style-type: square;
      font-size: 14px;
      font-weight: 400;
      width: 40%;
      margin-bottom: 5px;
      margin-left: 20px;
    }
  }

  .SubComponent {
    &-webhook-history {
      padding: 0 20px;
      margin-bottom: 30px;

      &-heading {
        margin-bottom: 10px;
        font-weight: 500;
      }
    }
  }
}