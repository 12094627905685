@import '~styles/variables';

.CustomerAccountSSO {

  .row {
    h3 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 18px;
      color: $text-color-primary;
    }
  }
}