@import '~styles/variables';

.SsoSettings {

  .row {
    h3 {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 18px;
      color: $text-color-primary;
    }

    &.btn-row {
      .Button {
        margin-top: 10px;
      }
    }
  }

  .create-user-switcher {
    display: flex;
    align-items: center;

    .Label {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}