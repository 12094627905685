.FormStepper {

  &-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 30px;

    .FormStepperItem {
      
      &-wrapper {
        border-right: none;
      }

      &:last-of-type {
        
        .FormStepperItem-wrapper {
          border-right: 1px solid #E4E4E5;
        }

        .triangle-right {
          display: none;
        }
      }
    }
  }
}