@import "~styles/variables";

.FormStepperItem {
  position: relative;

  &-wrapper {
    display: flex;
    border: 1px solid #E4E4E5;
    height: 70px;
    width: 300px;
    background-color: $white;

    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 70px;

      .stepNumber {
        font-size: 30px;
        font-weight: 600;
      }
    }

    .meta {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-weight: 600;
        font-size: 18px;
      }

      .description {
        font-size: 13px;
      }
    }
  }

  .triangle-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -12px;
    width: 0;
    z-index: 2;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 12px;
    border-color: transparent transparent transparent #e4e4e5;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.6px;
      width: 0;
      z-index: 2;
      height: 0;
      border-style: solid;
      border-width: 11px 0 11px 11px;
      border-color: transparent transparent transparent $white;
    }
  }

  &.active {

    .FormStepperItem-wrapper {
      background-color: #F3F4F5;

      .meta {

        .title {
          color: $blue;
        }
      }
    }

    .triangle-right {
      border-color: transparent transparent transparent #e4e4e5;

      &::after {
        border-color: transparent transparent transparent #F3F4F5;
      }
    }
  }
}