@import "~styles/variables";

.Summary {
    // position: absolute;
  // top: 0;
  // right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  align-self: flex-start;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px rgba(173, 173, 173, 0.1);

  &-heading {
    padding: 20px;
    border-radius: 4px 4px 0 0;
    font-weight: 600;
    font-size: 18px;
    background-color: $gray;
    color: $white;
  }

  &-content {
    padding: 40px 20px 10px;
    word-wrap: break-word;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid $form-element-border;

      .label {
        font-size: 14px;
        font-weight: 500;
      }

      .value {

        .currency {
          margin-left: 5px;
        }
      }

      &.total {
        font-weight: bold;
        margin-top: 30px;
        border-bottom: none;
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    padding: 0 40px 10px;

    .Button {
      width: 100%;
    }
  }
}