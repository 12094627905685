@import "~styles/variables";
@import "~styles/helpers";

$border-color: #E3E3E3;

.PlanTypeButton {

  &-input {

    input {
      position: fixed;
      left: -99999px;
      
      &:checked + label {
        border-color: $blue;
        
        &::after {
          opacity: 1;
        }
      }
  
      &:not(:checked) + label {

        &::after {
          opacity: 0;
        }
      }
    }
  
    label {
      position: relative;
      min-width: 150px;
      cursor: pointer;
      display: inline-block;
      padding: 20px 20px 20px 50px;
      border-radius: 4px;
      border: 1px solid $border-color;
      background: #fff;

      &:hover {
        border-color: $blue;
      }
  
      &::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 35%;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid $border-color;
        background: transparent;
      }
  
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        background: $blue;
        border-radius: 50%;
        position: absolute;
        top: 40%;
        left: 19px;
      }

      .plan-type-name {
        font-weight: 600;
        font-size: 18px;
      }

      .plan-type-desc {
        color: $text-color-secondary;
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }

  &-disabled {
    @include opacity(0.5);
    
    label {
      cursor: not-allowed;
      &:hover {
        border-color: $border-color;
      }
    }
  }
}