@import "~styles/variables";
@import "~styles/helpers";

.FastSpringForm {
    .TextArea {
        margin-bottom: 16px;

        textarea {
            max-width: 400px;
        }
    }

    .Selector {
        margin-bottom: 16px;
    }

    .Notice {
        max-width: 380px;
    }
}