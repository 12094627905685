@import '~styles/variables';

.ApiKeys {

  .row {
    margin-bottom: 30px;

    &.air-gapped {
      .Button {
        margin: 0;
      }

      .air-gap-loading {
        .ContentLoader {
          padding: 10px;
          text-align: left;

          &-spinner {
            width: 20px;
            height: 20px;
          }
        }
      }

      .air-gap-no-key {
        margin-top: 10px;
        font-weight: 600;
        font-size: 14px;
        color: $text-color-secondary;
      }
    }

    h3 {
      margin-top: 0px;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 18px;
      color: $text-color-primary;
    }

    .descriptive-header {
      display: flex;

      svg {
        margin-left: 5px;
      }
    }
  }

  .management-keys-list {

    .key-container {
      display: flex;
      align-items: center;

      span {
        margin-right: auto;
      }

      .clipboard-btn {
        display: inline-flex;
        align-items: center;
        z-index: 2;
        background-color: transparent;
        opacity: 0.5;
        -webkit-appearance: none;
        border: none;
        width: 34x;
        cursor: pointer;
        outline: none;
        margin-left: 10px;

        &:hover {
          background-color: #e2e2e2;
          opacity: 1;
        }
      }
    }
  }
}