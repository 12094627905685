@charset "UTF-8";
.PlanTypeContainer-heading .Subtitle {
  margin-top: 10px; }

.PlanTypeContainer-notice {
  margin-top: 15px; }

.PlanTypeContainer-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column; }
  .PlanTypeContainer-form .form-row {
    display: flex; }
    .PlanTypeContainer-form .form-row .PlanTypeButton {
      margin-right: 20px; }

.PlanTypeContainer .PlanTypeBox {
  background-image: linear-gradient(-45deg, #eef9fe, #f6f8fb);
  flex: 0 1 31.5%;
  align-self: flex-start;
  margin-right: 20px;
  padding: 42px 32px;
  margin-bottom: 60px;
  border-radius: 4px;
  background-color: #f0f9fe;
  max-width: 250px;
  text-align: center;
  box-shadow: 0px 0px 8px 2px rgba(173, 173, 173, 0.1); }
  .PlanTypeContainer .PlanTypeBox.current {
    border: 1px solid #44486c; }
  .PlanTypeContainer .PlanTypeBox-title {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 20px;
    color: #13b0fc;
    line-height: 1.25;
    font-weight: 700;
    letter-spacing: -.5px; }
  .PlanTypeContainer .PlanTypeBox-description {
    line-height: 1.45; }
  .PlanTypeContainer .PlanTypeBox-price {
    font-size: 46px;
    font-weight: 700;
    margin-top: 40px; }
    .PlanTypeContainer .PlanTypeBox-price.free:before, .PlanTypeContainer .PlanTypeBox-price.standard:before {
      top: -20px;
      margin: 0;
      margin-right: 3px;
      font-size: 15px;
      content: "$";
      position: relative;
      font-weight: 400; }
    .PlanTypeContainer .PlanTypeBox-price.free:after, .PlanTypeContainer .PlanTypeBox-price.standard:after {
      content: "/mo";
      margin: 0;
      font-size: 15px;
      font-weight: 400; }
    .PlanTypeContainer .PlanTypeBox-price.enterprise {
      display: flex;
      align-items: center;
      line-height: 1.5;
      font-weight: 400;
      color: #9ba6b5;
      font-size: 15px;
      max-width: 150px;
      margin: 0 auto;
      height: 147px; }
  .PlanTypeContainer .PlanTypeBox-btn .upgrade-link {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #10ac84;
    border: 1px solid #0d8767;
    text-decoration: none;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    height: 44px;
    padding: 0 40px;
    margin: 0 2px;
    transition: 150ms all ease-in-out; }
  .PlanTypeContainer .PlanTypeBox-features {
    text-align: left;
    margin: 32px 6% 0;
    list-style: none;
    padding: 0; }
    .PlanTypeContainer .PlanTypeBox-features li {
      line-height: 2.5;
      font-size: 15px; }
      .PlanTypeContainer .PlanTypeBox-features li.checkmark {
        margin-left: 24px; }
        .PlanTypeContainer .PlanTypeBox-features li.checkmark::before {
          content: "✓";
          color: #13b0fc;
          background-size: contain;
          background-position: 50%;
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: -24px;
          margin-right: 8px; }
      .PlanTypeContainer .PlanTypeBox-features li span {
        color: #13b0fc;
        font-weight: 700;
        margin-right: 5px; }
