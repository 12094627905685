@import "~styles/variables";

.NewCompanyForm {

  .form-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  
    @media screen and(max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  
    .Subtitle {
      margin: 10px 0;
    }
  
    .TextInput,
    .NumberInput {
      max-width: 300px;

      @media screen and(max-width: 1200px) {
        max-width: unset;
      }
  
      input {
        max-width: unset;
        min-width: unset;
        box-sizing : border-box;
      }
    }
  
    .hidden {
      visibility: hidden;
    }
  }

  .dev-checkbox {
    margin-top: 5px;
  }

  .linked-acc {
    margin-top: 10px;

    .Label {
      margin-top: 5px;
    }
  }
}