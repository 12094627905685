@import "~styles/variables";

.ResendForm {

  &-eventName {
    font-weight: 500;
  }

  &-errorNotice {
    margin-top: 20px;
    color: $text-color-secondary;
    font-size: 14px;
  }

  &-main {
    margin-top: 20px;

    .Label {
      margin-top: 8px;
    }
  }
}