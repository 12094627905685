@import "~styles/variables";
@import "~styles/helpers";

.ProductsStep {

  .bundle-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .Label {
      margin: 0;
    }

    .Switcher {
      margin-right: 10px;
      height: 16px;
    }
  }

  &-add-product {

    .Button {
      margin-bottom: 0;
    }

    .InputErrorMessage {
      margin-bottom: 6px;
    }
  }

  &-actions {
    margin-top: 30px;

    .Button {
      margin-right: 40px;
    }
  }
}