@import "~styles/variables";

.PasswordInput {
  position: relative;
  max-width: 300px;

  input {
    height: $form-element-height;
    width: 100%;
    max-width: $form-element-max-width;
    min-width: 300px;
    border: 1px solid $form-element-border;
    outline: none;
    padding: 0 10px;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;

    &:focus {
      border: 1px solid $form-element-border-focus;
    }

    &:disabled {
      background: $form-element-disabled-background;
      border-color: $form-element-disabled-border;
    }

    &.has-error {
      border-color: $red;
    }
  }

  .spinner {
    position: absolute;
    right: 31px;
    top: 4px;
  }

  button {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    outline: none;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}