@import "~styles/variables";

.RadioBtn {
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    color: $text-color-primary;

    span {
      display: flex;

      .Tooltip {
        position: relative;

        span {
          position: absolute;
          top: -2px;
          left: 4px;

          svg {
            cursor: help;
            vertical-align: super;
          }
        }
      }
    }
  }

  input {
    margin-right: 10px;
    margin-left: 0;
    margin-top: 0;
  }

  &-disabled {
    
    label {
      color: $gray;
    }
  }
}