@import '~styles/variables';
@import '~styles/helpers';

.GlobalError {
  @include flexMiddleCenter;
  padding: 100px 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 340px;

    .Subtitle {
      margin-top: 10px;
    }

    p {
      text-align: center;
      color: $text-color-primary;
    }
  }
}