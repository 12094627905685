@import "~styles/variables";

.BillingContainer {

  &-notice {
    margin: 10px 0;
  }

  &-info {
    margin-top: 30px;

    h2 {
      margin: 0 0 5px;
      font-size: 18px;
    }
  }

  .DescriptionTable-label {
    display: flex;
    align-items: center;
    
    .icon-cont {
      margin-left: 5px;
    }
  }
}