
@import "~styles/helpers";

.LabelTag {
  font-size: 11px;
  margin: 10px 10px 0 0;
  padding: 2px 0 2px 6px;
  color: #f7f7f7;
  display: flex;
  align-items: center;

  .label-delete-btn {
    @include appearance(none);
    border: 0;
    cursor: pointer;
    background: transparent;
    outline: none;
    padding: 0;
  }
}